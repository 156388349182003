import HomepageLayout from './HomepageLayout';


function App() {
  return (
    <div className="App">
      <header className="App-header" />
      <HomepageLayout />

    </div>
  );
}

export default App;
